import React from 'react';
import TogetherBanner from 'components/togetherBanner';
import Headquarters from 'components/headquarters';
import VideoYouTube from 'components/video';
import SEO from 'components/seo';
import { Link } from 'gatsby';
import Herotech8Banner from 'components/herotech8Banner';
import { Col, Container, Row } from 'react-bootstrap';
import HomeHeader from './homeHeader';
import OurBrochure from './ourBrochure';
import WhatWeStand from './whatWeStand';
import ProjectsClientsLogos from '../../components/projectsClientsLogos';
// import Testimonials from './testimonials';
// import OurClients from './ourClients';

function Home() {
  return (
    <>
      <SEO title="A true Innovation Technology company building state-of-the-art solutions." />
      <HomeHeader />

      <div className="py-6 bg-primary">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 pl-md-8 pr-md-10 mx-auto font-weight-light">
              <h5 className="">
                <span className="text-white font-weight-bold">Emvelia (or Emveleia)</span> <span className="text-white">/ εm.ˈvε.li.a /</span> <span className="font-italic">originates from the Greek word Εμβέλεια, which stands for Range or Scope; the interval that a signal can effectively cover; the area of the program where an entity is accessible. Metaphorically, it means the impact that someone or something has.</span>
              </h5>
            </div>
          </div>
        </div>
      </div>

      <WhatWeStand />

      <div className="container-fluid">
        <div className="row mb-5 my-md-6 my-lg-8">
          <div className="col-12 col-lg-9 mx-auto text-center">
            <h1 className="font-weight-bolder">We Revolutionise Industries</h1>

            <div style={{ boxShadow: '0 4px 4px 0 rgba(0,0,0,.4)', padding: 20 }}>
              <VideoYouTube url="https://www.youtube.com/embed/0VnNH7AXMVU" />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <h1 className="font-weight-bolder underscore">Our<br />mission</h1>
      </div>

      <section className="d-flex align-items-center parallax">
        <div className="container-fluid">

          <div className="row justify-content-start align-items-center text-left">
            <div className="col-12 col-md-5">
              <h1 className="font-weight-light text-white">To improve life<br />through technology.</h1>
            </div>
          </div>
        </div>
      </section>

      {/* <OurClients /> */}
      {/* <Testimonials /> */}

      <Container fluid>
        <Row className="mb-5 my-md-6 my-lg-8">
          <Col xs={12} md={6} className="mb-4 mb-lg-0 ">
            <h1 className="font-weight-bolder mt-0 underscore">Our latest Case Study</h1>
            <h2 className="display-4 font-weight-bold">Smart Wearables in Healthcare (I.C.A.C. / Κ.Α.Ε.Β)</h2>

            <div className="display-6 font-weight-bold my-3">HaaS & PaaS, Healthcare</div>
            <div className="display-6 font-weight-light">
              Funded by the Hellenic Ministry of Digital Governance, the Immediate Communication & Assistance Centre, also known in Greece as Κέντρο Άμεσης Επικοινωνίας & Βοηθείας (ΚΑΕΒ), is a state-of-the-art Hardware & Platform as a Service, implementing smart wearables and medical profiles to provide 24/7 Healthcare Support for Municipalities.
            </div>

            <Link to="/case-studies/information-communication-assistance-center-kaeb" className="btn btn-primary mt-5">Read the Case Study</Link>
          </Col>
          <Col xs={12} md>
            <Link to="/case-studies/information-communication-assistance-center-kaeb">
              <img src="/assets/what-we-do/icac.jpg" className="img-fluid img-thumbnail" alt=" " />
            </Link>
          </Col>
        </Row>
      </Container>

      <Herotech8Banner action="url" />

      <OurBrochure />

      <ProjectsClientsLogos />

      <Headquarters />
      <TogetherBanner />
    </>
  );
}

export default Home;
