import React from 'react';


function HomeHeader() {
  return (
    <div className="wrapper home-header">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-6 pl-md-8 py-6 py-md-8 py-lg-10">
            <div className="home-header-title">
              <h1 className="font-weight-bolder mt-md-6 text-primary">
                <span className="text-white">Emvelia</span> is an Innovation Technology company creating state-of-the-art solutions
              </h1>
            </div>
            <div className="my-3" style={{ borderBottom: '1px dashed #fff' }} />
            <div className="pt-2 text-white">
              <h5>We are pioneering the use of technology for business, taking visions and turning them into reality. Because innovative ideas need cutting-edge solutions.</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeHeader;
