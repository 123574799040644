import React from 'react';


function WhatWeStand() {
  return (
    <div className="wrapper mt-6">
      <div className="container-fluid what-we-stand">
        <h1 className="font-weight-bolder underscore">Why us</h1>
        <h4 className="font-weight-light text-secondary">We love solving problems with groundbreaking technology, bringing new ideas to life and creating solutions nobody else has thought of. Our business is built on three key pillars:</h4>
        <div className="row">
          <div className="col-12 col-lg-4 pr-lg-0 d-flex">
            <div className="bg-primary px-7 py-4">
              <h1 className="text-secondary white-underscore font-weight-bolder my-4">teamwork</h1>
              <div className="display-5 text-white font-weight-light">
                <p>
                  Bringing together innovators, big thinkers and talented experts, we put our dynamic team at your disposal.
                </p>
                <p>
                  We explore the very edges of creativity and collaboration to pursue exciting ideas for business.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 d-flex">
            <div className="bg-secondary px-7 py-4">
              <h1 className="text-primary white-underscore font-weight-bolder my-4">quality</h1>
              <div className="display-5 text-white font-weight-light">
                <p>
                  Our solutions make a difference. They aren’t just impressive, they’re effective at leading business improvement.
                </p>
                <p>
                  We create, test and then test again, all to make sure that our projects perform exactly as planned from day one.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 pl-lg-0 d-flex">
            <div className="bg-secondary-light px-7 py-4">
              <h1 className="white-underscore font-weight-bolder my-4">passion</h1>
              <div className="display-5 text-white font-weight-light">
                <p>
                  We love to pursue exciting new technologies and create visionary products that make a difference in the lives of their users.
                </p>
                <p>
                  It’s what drives us each day to continue creating new cutting-edge innovative solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatWeStand;
